<div fxFlex fxLayout="row">
  @if (results$ | async; as results) {
    <!-- Menu with result totals -->
    <app-custom-list fxHide fxShow.gt-sm fxFlex="20" class="abs-margin abs-margin-double-top abs-padding-horizontal" mode="dense">
      <app-custom-list-item (click)="setType(null)" fxLayoutAlign="start center">
        <h4>
          <span class="abs-bold">All</span>
          ({{ results.total.value }})
        </h4>
      </app-custom-list-item>
      @for (section of results | keyvalue: returnZero; track section) {
        @if (section.value.total?.value > 0) {
          <app-custom-list-item (click)="setType(section.key)" fxLayoutAlign="start center">
            <h4>
              <span class="abs-bold">{{ typeLabels[section.key] }}</span>
              ({{ section.value.total.value }}{{ section.value.total.relation === 'gte' ? '+' : '' }})
            </h4>
          </app-custom-list-item>
        }
      }
    </app-custom-list>
    <!-- Results  -->
    <div fxFlex fxFlex.gt-sm="60" class="abs-padding tw-overflow-hidden">
      @for (section of results | keyvalue: returnZero; track section) {
        @if ((activeType == null || activeType === section.key) && section.value.total?.value > 0) {
          <mat-card class="mat-elevation-z4 abs-margin-vertical">
            <mat-card-header>
              <mat-card-title>{{ typeLabels[section.key] }}</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <app-custom-list class="abs-margin-bottom">
                @for (hit of activeType ? section.value.hits : section.value.hits.slice(0, 5); track hit; let index = $index) {
                  <div (click)="clicked(section.key, hit._source, index)">
                    @switch (section.key) {
                      @case ('crm_company') {
                        <app-contact-item [contact]="hit._source"></app-contact-item>
                      }
                      @case ('crm_contact') {
                        <app-contact-item [contact]="hit._source"></app-contact-item>
                      }
                      @case ('crm_log') {
                        <app-log-list-item [log]="hit._source"></app-log-list-item>
                      }
                      @case ('crm_account') {
                        <app-account-list-item [account]="hit._source"></app-account-list-item>
                      }
                      @case ('crm_document') {
                        <app-document-list-item [document]="hit._source" [showLog]="true" [showEdit]="true" trackingFeature="super_search" />
                      }
                    }
                  </div>
                }
              </app-custom-list>
            </mat-card-content>
            <mat-card-actions>
              @if (section.value.total.value > (activeType ? section.value.hits.length : 5)) {
                <button mat-button class="abs-margin-left" (click)="showMore(section.key)">Show more</button>
              }
            </mat-card-actions>
          </mat-card>
        }
      }
    </div>
  }
</div>
