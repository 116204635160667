<div class="tab-tools abs-margin-right" fxLayoutAlign="end center"></div>

@if (showChip) {
  <mat-chip-listbox class="abs-margin-half-top abs-margin-half-left">
    @if (portfolio) {
      <mat-chip (removed)="removePortfolio()">
        {{ portfolio.ticker }}
        <button matChipRemove>
          <mat-icon fontIcon="mdi-close"></mat-icon>
        </button>
      </mat-chip>
    }
  </mat-chip-listbox>
}

<app-custom-list fxFlex fxLayout="column">
  <cdk-virtual-scroll-viewport fxFlex fxLayout="column" itemSize="44" [ngClass]="{ 'abs-scroll-hidden': loading }">
    <!-- Skeleton items while loading -->
    <app-list-skeleton-loader [lines]="2" [height]="44" [items]="20" [loading]="loading()"></app-list-skeleton-loader>

    <app-custom-list-item
      *cdkVirtualFor="let opportunity of opportunities$ | async"
      [selected]="opportunity.idtContact === selectedConsultant?.idtContact"
      (click)="select(opportunity)"
    >
      <div class="tw-flex tw-max-w-[100%] tw-flex-1 tw-items-center">
        <div fxFlex="70" fxLayout="column" class="column-name">
          <span>
            <div style="display: flex">
              <app-contact-context-menu
                class="abs-bold abs-text-overflow-ellipsis"
                [matTooltip]="opportunity.name"
                [displayText]="opportunity.name"
                [id]="opportunity.idtContact"
              ></app-contact-context-menu>
            </div>
          </span>
          <span class="abs-color-faded">{{ opportunity.count }} {{ opportunity.count > 1 ? 'opportunities' : 'opportunity' }}</span>
        </div>
        <div fxFlex="30" fxLayout="column" fxLayoutAlign="center end" class="column-amount">
          <span class="abs-bold">{{ opportunity.size | nearUnitNumber: '0.0-0' }}</span>
          <div fxLayout="row" class="abs-color-faded">
            <span>{{ opportunity.portfolios[0] }}</span>
            @if (opportunity.portfolios.length > 1) {
              <span>&nbsp;+ {{ opportunity.portfolios.length - 1 }}</span>
            }
          </div>
        </div>
      </div>
      <mat-divider></mat-divider>
    </app-custom-list-item>
  </cdk-virtual-scroll-viewport>
</app-custom-list>
