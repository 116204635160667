<app-dialog-title displayTitle="Addresses" [loading]="saving() || loading()">
  <button mat-icon-button type="button" (click)="addContactAddressForm()" [disabled]="saving()">
    <mat-icon fontIcon="mdi-plus-circle" color="primary"></mat-icon>
  </button>
</app-dialog-title>

<mat-dialog-content>
  <form (submit)="save()" id="address-form">
    <div class="tw-flex tw-flex-col">
      @for (address of companyAddresses$ | async; track address) {
        <div class="abs-dashed-box dark abs-margin-bottom">
          <div fxLayout="row" fxLayoutAlign="start end">
            <mat-icon fontIcon="mdi-office-building-marker" class="abs-margin-right"></mat-icon>
            <span class="abs-bold">Company Address</span>
          </div>
          <div class="address-space">
            <div>{{ [address.location, address.city] | join: ' - ' }}</div>
            <div>{{ [address.state, address.country, address.zipCode] | join: ' - ' }}</div>
          </div>

          <mat-slide-toggle
            class="tw-mt-1"
            [checked]="idtFavoriteAddress() === address.idtAddress"
            (change)="setFavoriteAddress($event, address.idtAddress)"
          >
            Primary address
          </mat-slide-toggle>
        </div>
      }
      @for (addressForm of formArray.controls; track addressForm; let i = $index) {
        <app-contact-address-form
          class="abs-dashed-box abs-margin-bottom"
          [contactAddressForm]="addressForm"
          [index]="i"
          (remove)="remove($event)"
          [ngClass]="{ 'invalid-form': addressForm.touched && addressForm.hasError('atLeastOneRequired') }"
          (markedFavorite)="changeFavoriteAddress(i)"
        ></app-contact-address-form>
      }
    </div>
  </form>
</mat-dialog-content>

<div mat-dialog-actions>
  <button mat-button matDialogClose>Cancel</button>
  <span class="tw-flex-1"></span>
  <button mat-raised-button color="primary" type="submit" [disabled]="saving()" form="address-form">Save</button>
</div>
