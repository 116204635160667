<div class="abs-margin-right @xs:tw-mt-0 tw-mt-[36px] tw-@container @xs:tw-h-[36px]" fxLayoutAlign="end center">
  <button
    mat-icon-button
    matTooltip="Expand"
    [routerLink]="['', { outlets: { sidenav: ['opportunity'] } }]"
    [queryParams]="{ 'sidenav.idtContact': idtContact }"
    color="primary"
  >
    <mat-icon fontIcon="mdi-book-open"></mat-icon>
  </button>
</div>

@if (hasBlackoutOpportunity$ | async) {
  <app-alert-box><span class="tw-uppercase">There are opportunities under a blackout period</span></app-alert-box>
}

@if (groupedOpportunities) {
  <div fxLayout="row" class="abs-padding" fxLayoutGap="4px">
    @for (group of groupingOptions; track group) {
      <button
        mat-icon-button
        [matTooltip]="getTooltip(group)"
        [color]="groupBy === group.property ? 'accent' : 'grey'"
        (click)="groupByProperty(group.property)"
      >
        <mat-icon [fontIcon]="group.icon"></mat-icon>
      </button>
    }
  </div>
  <div fxFlex fxLayout="column" class="abs-padding">
    <app-opportunity-list-header></app-opportunity-list-header>
    <div class="abs-scroll-auto" fxFlex>
      @for (group of groupedOpportunities | keyvalue: sortGroups; track group) {
        <div>
          @if (showTitle()) {
            <h3 class="abs-margin-vertical">
              @if (groupBy === 'portfolio') {
                <app-portfolio-context-menu [id]="group.value[0].idtPortfolio" [displayText]="group.key"></app-portfolio-context-menu>
              } @else {
                {{ group.key }}
              }
            </h3>
          }
          <app-custom-list>
            @for (opportunity of group.value; track opportunity) {
              <app-opportunity-list-item [opportunity]="opportunity"></app-opportunity-list-item>
            }
          </app-custom-list>
        </div>
      }
    </div>
  </div>
}
