<app-progress-bar [loading]="loading()" />
<div mat-dialog-title class="!tw-flex tw-flex-col">
  <div class="tw-flex tw-flex-row tw-items-center">
    <span class="tw-flex-1">{{ displayTitle() }}</span>

    <ng-content></ng-content>

    <button mat-icon-button matDialogClose class="abs-margin-left"><mat-icon fontIcon="mdi-close"></mat-icon></button>
  </div>
</div>
