<app-dialog-title displayTitle="Phones" [loading]="saving() || loading()">
  <button mat-icon-button type="button" (click)="addContactPhoneForm()" [disabled]="saving()">
    <mat-icon fontIcon="mdi-plus-circle" color="primary"></mat-icon>
  </button>
</app-dialog-title>

<mat-dialog-content>
  <form (submit)="save()" id="phones-form">
    <div class="tw-flex tw-flex-col">
      @for (phoneForm of formArray.controls; track phoneForm; let i = $index) {
        <app-contact-channel-form
          [channel]="ContactChannelEnum.PHONE"
          [contactChannel]="getPhone(i)"
          [contactChannelForm]="$any(phoneForm)"
          [index]="i"
          (remove)="remove($event)"
          (defaultChannel)="handleFormFieldFlag($event, 'defaultChannel')"
          class="abs-dashed-box abs-margin-bottom"
        ></app-contact-channel-form>
      }
    </div>
  </form>
</mat-dialog-content>

<div mat-dialog-actions>
  <button mat-button matDialogClose>Cancel</button>
  <span class="tw-flex-1"></span>
  <button mat-raised-button color="primary" type="submit" [disabled]="saving()" form="phones-form">Save</button>
</div>
