<button
  mat-icon-button
  matTooltip="View tasks"
  matBadge="1"
  [ngClass]="{ 'warn-badge': hasSoonTask, 'error-badge': hasLateTask }"
  matBadgeSize="small"
  matBadgePosition="below after"
  [matBadgeHidden]="!(hasSoonTask || hasLateTask)"
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin"
  (click)="idtContact ? (isOpen = !isOpen) : openSidenav()"
>
  <mat-icon fontIcon="mdi-bell"></mat-icon>
</button>
<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayFlexibleDimensions]="true"
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  [cdkConnectedOverlayPush]="true"
  (backdropClick)="isOpen = !isOpen"
  cdkConnectedOverlayWidth="400px"
>
  <div class="mat-elevation-z8 abs-overflow-hidden" fxFlex>
    <div class="abs-padding-double abs-bold">Tasks</div>

    @if (tasks.length > 0) {
      <div class="abs-scroll-auto" style="max-height: 500px">
        <app-custom-list>
          @for (task of tasks; track task) {
            <app-task-list-item [task]="task" (click)="close()" class="abs-block abs-padding-horizontal"></app-task-list-item>
          }
        </app-custom-list>
      </div>
    } @else {
      <div class="abs-padding abs-bold">No tasks to complete</div>
    }

    <div class="abs-padding" fxLayoutAlign="space-between">
      <button
        fxFlex
        fxFlex.gt-xs="none"
        mat-button
        color="primary"
        (click)="newTask()"
        [routerLink]="['', { outlets: { sidenav: ['task', 'add'] } }]"
      >
        New task
      </button>
      <button
        fxFlex
        fxFlex.gt-xs="none"
        mat-button
        color="primary"
        [routerLink]="['', { outlets: { sidenav: ['task'] } }]"
        [queryParams]="{ 'sidenav.idtContact': idtContact, 'sidenav.tab': 2 }"
        (click)="isOpen = !isOpen"
      >
        Tasks
      </button>
    </div>
  </div>
</ng-template>
