import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, finalize } from 'rxjs/operators';
import { Pageable } from './pageable';

/**
 * A simple datasource for infinite scrolling tables that can't rely on angular cdk-scrolling, like mat-tables.
 */
export class PagedRenderingDataSource<T> extends DataSource<T> {
  /**
   * The already loaded entities.
   */
  entities: T[] = [];

  /**
   * The pageable information.
   */
  private pageable?: Pageable<T>;

  /**
   * Total elements mathching the query criteria
   */
  get totalElements(): number {
    return this.pageable?.totalElements || 0;
  }

  /**
   * The next page number.
   */
  private nextPage = 0;

  /**
   * Flag to indicate if there are more results to load.
   */
  private hasMore = true;

  /**
   * Flag indicating if data is being loaded from the server.
   */
  loading = false;

  private loadingSubject = new BehaviorSubject<boolean>(false);

  /**
   * Observable to listen for loading changes.
   */
  loading$ = this.loadingSubject.asObservable().pipe(distinctUntilChanged());

  /**
   * Subject to emit loaded entities.
   */
  private subject = new BehaviorSubject<T[]>([]);

  /**
   * Subscriptions to the request to get the next page. Allows to cancel the request if needed.
   */
  private subscription?: Subscription;

  constructor(
    private getPage: (
      page: number,
      size: number,
      sort: string | string[],
      filters: string,
      selectedColumns: string[],
      entities?: string[],
    ) => Observable<Pageable<T>>,
    private sort: string | string[],
    private limit: number = 50,
    private filters: string = '',
    private selectedColumns: string[] = [],
    private joins: string[] = [],
  ) {
    super();
  }

  /**
   * Get the observable that will emit the entities to be displayed.
   */
  connect(): Observable<T[]> {
    return this.subject.asObservable();
  }

  /**
   * Handles when the datasource is destroyed. Completes the observable to avoid memory leaks.
   */
  disconnect(): void {
    this.subscription?.unsubscribe();
    this.subject.complete();
  }

  /**
   * Load a new page of data.
   */
  loadMore(): void {
    if (this.hasMore && !this.loading) {
      this.loading = true;
      this.loadingSubject.next(true);

      this.subscription = this.getPage(this.nextPage, this.limit, this.sort, this.filters, this.selectedColumns, this.joins)
        .pipe(
          finalize(() => {
            this.loading = false;
            this.loadingSubject.next(false);
          }),
        )
        .subscribe((result) => {
          this.pageable = result;
          this.entities = this.entities.concat(result.content);
          this.subject.next(this.entities);
          this.nextPage += 1;

          this.hasMore = this.nextPage <= this.pageable.totalPages;
        });
    }
  }
}
