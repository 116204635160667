import { Component, DestroyRef, EventEmitter, input, Input, OnDestroy, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { DataFilter } from 'app/modules/common/framework/model/data-filter';
import { PageableDataSource } from 'app/modules/common/framework/pagination/pageable-datasource';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { OpportunityService } from '../../../opportunity/services/opportunity.service';
import { ContactList, ContactTypeEnum, ContactViewTO } from '../../model/contact.model';
import { ContactService } from '../../services/contact.service';

/**
 * Contact list component
 */
@Component({
  selector: 'app-contact-list',
  templateUrl: './contact-list.component.html',
  styleUrls: ['./contact-list.component.scss'],
})
export class ContactListComponent implements OnDestroy {
  /**
   * Indicates if there is an opportunity under a blackout period.
   */
  hasBlackoutOpportunity$: Observable<boolean> = of(false);

  /**
   * The id of the contact.
   */
  private _idtContact!: number;

  @Input()
  set idtContact(id: number) {
    this._idtContact = id;

    this.hasBlackoutOpportunity$ = this.opportunityService.hasBlackoutOpportunity(id);

    this.contactService
      .getInfoById(id)
      .pipe(
        tap((data) => {
          this.contact = data;
          this.loadContacts();
        }),
      )
      .subscribe();
  }

  get idtContact(): number {
    return this._idtContact;
  }

  /**
   * Indicates if should search for external contacts
   */
  @Input()
  external = false;

  /**
   * Whether to show the search field.
   */
  @Input()
  showSearch = false;

  /**
   * Event emitted when loading data from server.
   */
  @Output()
  private loading = new EventEmitter<boolean>(true);

  /**
   * A page of contacts
   */
  contactData!: PageableDataSource<ContactList>;

  /**
   * The contact
   */
  private contact!: ContactViewTO;

  /**
   * Field used to search for contact
   */
  searchField = new FormControl<string>('', { nonNullable: true });

  subscription = this.searchField.valueChanges.pipe(debounceTime(1000), distinctUntilChanged()).subscribe((term) => {
    const filter = new DataFilter().like('name', `%${term}%`, 'string'); // `name|like|%${term}%|string`;
    this.loadContacts(filter);
  });

  /**
   * Show investment status column.
   */
  showInvestmentStatus = input<boolean>(true);

  constructor(
    private contactService: ContactService,
    private destroyRef: DestroyRef,
    private opportunityService: OpportunityService,
  ) {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private loadContacts(filter: DataFilter = new DataFilter()): void {
    filter = this.applyInitialFilter(filter);
    const limit = 20;

    if (this.external) {
      this.contactData = this.contactService.searchRelationship(this.idtContact, filter.encode(), this.getSort(), limit);
    } else {
      this.contactData = this.contactService.search(filter.encodeURIComponent(), this.getSort(), limit);
    }

    this.contactData.loading$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((loading) => {
      this.loading.next(loading);
    });
  }

  /**
   * Gets the initial condition of the filter
   * @returns the initial condition of filter
   */
  applyInitialFilter(initialFilter: DataFilter): DataFilter {
    if (!this.contact.idtCompany) {
      return initialFilter;
    }

    if (this.external) {
      initialFilter = initialFilter.notEqual('idtCompany', this.contact.idtCompany, 'long').isNull('idtCompany');
      initialFilter.or();
    } else {
      initialFilter = initialFilter.equals('type', ContactTypeEnum.PERSON, 'contacttypeenum');

      // If the contact is a branch, select contacts from the branch.
      // If the contact is a parent company, load all contacts from the company and all branches
      if (this.contact.branch) {
        initialFilter = initialFilter.equals('idtCompany', this.contact.idtCompany, 'long');
      } else {
        initialFilter = initialFilter.equals(['idtCompany', 'idtParentCompany'], this.contact.idtCompany, 'long');
      }
    }

    return initialFilter;
  }

  /**
   * Gets the list of items for sortable
   * @returns the list of sortable items
   */
  getSort(): string[] {
    return ['keyContact,DESC', 'type,ASC', 'name,ASC'];
  }
}
