<app-custom-list-item>
  @if (!last) {
    <mat-divider></mat-divider>
  }
  <div class="tw-flex tw-max-w-[100%] tw-flex-1 tw-items-center tw-gap-1">
    @if (selectable) {
      <div>
        <mat-checkbox (click)="$event.stopPropagation()" [checked]="selected" (change)="selectedChange.emit($event.checked)"></mat-checkbox>
      </div>
    }

    <div class="abs-align-center tw-flex tw-max-w-[15%] tw-flex-1 tw-flex-col @xs:tw-max-w-[6%]">
      <span>{{ transaction.transactionDate | date: 'MM/dd' : 'UTC' }}</span>
      <span>{{ transaction.transactionDate | date: 'yyyy' : 'UTC' }}</span>
    </div>
    <div class="abs-text-overflow-ellipsis tw-flex tw-flex-1 tw-flex-col" matTooltip="{{ transaction.accountName }}">
      <span class="abs-container-gt-xs abs-text-overflow-ellipsis">{{ transaction.accountName }}</span>

      <span class="abs-text-overflow-ellipsis abs-container-xs">{{ transaction.accountName }}</span>
      <span class="mat-caption abs-text-overflow-ellipsis abs-container-xs">{{ transaction.portfolioTicker }} - {{ transaction.className }}</span>
    </div>

    <div class="abs-container-gt-xs tw-flex tw-max-w-[24%] tw-flex-1 tw-flex-col">
      <span class="abs-text-overflow-ellipsis">{{ transaction.portfolioTicker }}</span>
      <span>{{ transaction.className }}</span>
    </div>

    <div class="abs-container-gt-xs tw-flex tw-max-w-[13%] tw-flex-1 tw-flex-col">
      @if (transaction.mode) {
        <div>{{ transaction.mode | titlecase }}</div>
      }
      <div>{{ transaction.type }}</div>
    </div>

    <div class="abs-align-right tw-flex tw-max-w-[20%] tw-flex-1 tw-flex-col @xs:tw-max-w-[13%]">
      @if (transaction.value !== 0) {
        <app-positive-negative [value]="transaction.value">
          <span>{{ transaction.value | number: '0.0-0' }}</span>
        </app-positive-negative>
      }
      <div class="mat-caption abs-container-xs">{{ transaction.type }}</div>
    </div>
    <mat-icon
      [fontIcon]="clientIcons[transaction.status]"
      matTooltip="{{ transaction.pendingStatus | removeUnderline | titlecase }}"
      class="abs-margin-half-right tw-flex-none"
    ></mat-icon>
  </div>
</app-custom-list-item>
