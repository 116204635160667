@if (showSearch) {
  <mat-form-field class="vertical-align" subscriptSizing="dynamic">
    <mat-label>Search</mat-label>
    <input matInput type="text" [formControl]="searchField" />
  </mat-form-field>
  <mat-divider></mat-divider>
}

@if (!external && (hasBlackoutOpportunity$ | async)) {
  <app-alert-box><span class="tw-uppercase">Do not contact them regarding the pending RFP</span></app-alert-box>
}

@if (contactData && !contactData.hasData) {
  <app-no-data [entity]="'contacts'"></app-no-data>
}

<app-custom-list fxFlex fxLayout="column">
  <cdk-virtual-scroll-viewport
    fxFlex
    fxLayout="column"
    itemSize="50"
    [ngClass]="{ 'abs-scroll-hidden': !contactData || (contactData.loading && contactData.totalElements === 0) }"
  >
    <!-- Sekeleton items while loading -->
    <app-list-skeleton-loader
      [lines]="2"
      [height]="50"
      [showAvatar]="true"
      [items]="10"
      [loading]="!contactData || (contactData.loading && contactData.totalElements === 0)"
    ></app-list-skeleton-loader>

    <app-contact-item
      *cdkVirtualFor="let contact of contactData"
      [contact]="contact"
      [active]="idtContact === contact.idtContact"
      [showInvestmentStatus]="showInvestmentStatus()"
    ></app-contact-item>
  </cdk-virtual-scroll-viewport>
</app-custom-list>
