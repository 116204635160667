import { BasicContact } from '../../contact/model/contact.model';
import { PortfolioTO } from '../../portfolio/model/portfolio.model';
import { User } from '../../user/user.model';

/**
 * Portfolio and class data in tasks.
 */
export interface TaskPortfolioClass {
  idtClass?: number;
  name?: string;
  idtPortfolio: number;
  portfolio: string;
}

/**
 * Enum options for task types.
 */
export enum TaskTypeEnum {
  MATERIAL_PRESENTATION_NEEDED = 'MATERIAL_PRESENTATION_NEEDED',
  SETUP_MEETING = 'SETUP_MEETING',
  CONTACT = 'CONTACT',
  SETUP_ABSOLUTE_DEMO = 'SETUP_ABSOLUTE_DEMO',
  ADD_DISTRIBUTION_LIST = 'ADD_DISTRIBUTION_LIST',
}

export enum TaskStatusEnum {
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
}

export interface TaskType {
  value: TaskTypeEnum;
  icon: string;
  text: string;
  shortText: string;
}

/**
 * Object for setting up icon and text options for task types.
 */
export const TaskTypes: TaskType[] = [
  {
    value: TaskTypeEnum.MATERIAL_PRESENTATION_NEEDED,
    icon: 'mdi-presentation',
    text: 'Materials / Presentation needed',
    shortText: 'Materials/Presentation',
  },
  {
    value: TaskTypeEnum.SETUP_MEETING,
    icon: 'mdi-account-multiple',
    text: 'Set up meeting',
    shortText: 'Meet',
  },
  {
    value: TaskTypeEnum.CONTACT,
    icon: 'mdi-account-voice',
    text: 'Contact (phone, email, etc)',
    shortText: 'Contact',
  },
  {
    value: TaskTypeEnum.SETUP_ABSOLUTE_DEMO,
    icon: 'mdi-presentation-play',
    text: 'Set up Absolute demo',
    shortText: 'ABSolute demo',
  },
  {
    value: TaskTypeEnum.ADD_DISTRIBUTION_LIST,
    icon: 'mdi-account-multiple-plus',
    text: 'Add to distribution list',
    shortText: 'Add to distribution list',
  },
];

/**
 * Model for the object to send to create tasks.
 */
export interface TaskCreateInputDTO {
  type: TaskTypeEnum;
  dueDate: string;
  comment: string;
  idtUsers: number[];
  idtContacts: number[];
  idtPortfolios: number[];
  dueDateChangeReason: string | null;
  status: TaskStatusEnum;
  customPortfolio: boolean;

  // Material/Presentation
  meetingType?: TaskMeetingTypeEnum | null;
  materialType?: TaskMaterialTypeEnum | null;
  copies?: number | null;
  classes?: TaskPortfolioClass[];
  attendees?: number[];
  presentationDate?: string;
}

/**
 * Task data
 */
export interface TaskViewTO {
  idtTask: number;
  idtUser: number;
  type: TaskTypeEnum;
  dueDate: string;
  status: TaskStatusEnum;
  comment: string;
  contacts: BasicContact[];
  portfolios: PortfolioTO[];
  users: User[];
  modificationDate: string;
  modificationUser: string;
  lastDateChangeReason?: string;
  customPortfolio: boolean;
  investmentStatus: string;
  dueStatus: string;
  firstPortfolio?: string;
  typeDescription: string;
  closedAt?: string;

  // Material/Presentation

  copies: number;
  meetingType: TaskMeetingTypeEnum;
  materialType: TaskMaterialTypeEnum;
  classes: TaskPortfolioClass[];
  attendees: User[];
  presentationDate: string;
}

/**
 * Maps server response for task count per due status.
 */
export interface TaskCountPerStatus {
  all: number;
  due: number;
  toDo: number;
  overdue: number;
  completed: number;
}

export enum TaskMaterialTypeEnum {
  HARD_COPY = 'HARD_COPY',
  SOFT_COPY = 'SOFT_COPY',
}

export enum TaskMeetingTypeEnum {
  INTRODUCTORY_MEETING = 'INTRODUCTORY_MEETING',
  UPDATE = 'UPDATE',
  FINALS_PITCH = 'FINALS_PITCH',
  CONFERENCE = 'CONFERENCE',
}
