<button mat-icon-button cdkOverlayOrigin #trigger="cdkOverlayOrigin" (click)="isOpen = !isOpen" matTooltip="More details">
  <mat-icon fontIcon="mdi-information-outline"></mat-icon>
</button>
<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen"
  [cdkConnectedOverlayHasBackdrop]="true"
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  (backdropClick)="isOpen = !isOpen"
>
  <div class="mat-elevation-z8 abs-padding abs-margin-double-horizontal">
    <div>
      @if (contactData.companyName) {
        <div class="info-item abs-padding-horizontal">
          <app-output label="Company">
            <span>{{ contactData.companyName }}</span>
          </app-output>
        </div>
      }
      @if (contactData.parentCompanyName) {
        <div class="info-item abs-padding-horizontal">
          <app-output label="Parent Company">
            <span>{{ contactData.parentCompanyName }}</span>
          </app-output>
        </div>
      }
      @if (contactData.anniversaryDate) {
        <div class="info-item abs-padding-horizontal">
          <app-output label="Birthday" class="tw-flex-1">
            {{ contactData.anniversaryDate | date: 'MM/dd/y' }}
          </app-output>
        </div>
      }
      @if (contactData.creationDate) {
        <div class="info-item abs-padding-horizontal">
          <app-output label="Active Since" class="tw-flex-1">
            {{ contactData.creationDate | date: 'MM/dd/y' }}
          </app-output>
        </div>
      }
      @if (contactData.contactGift) {
        <div class="info-item abs-padding-horizontal">
          <app-output label="Gift Contact Type" class="tw-flex-1">
            {{ contactData.contactGift }}
          </app-output>
        </div>
      }
      @if (contactData.spouseName) {
        <div class="info-item abs-padding-horizontal">
          <app-output label="Spouse" class="tw-flex-1">
            {{ contactData.spouseName }}
          </app-output>
        </div>
      }
      @if (contactData.contactKey) {
        <div class="info-item abs-padding-horizontal">
          <app-output label="Contact Key" class="tw-flex-1">
            {{ contactData.contactKey }}
          </app-output>
        </div>
      }
      @if (contactData.idtClient) {
        <div class="info-item abs-padding-horizontal">
          <app-output label="Category">
            <span>{{ contactData.category }}</span>
          </app-output>
        </div>
      }
      <div class="info-item abs-padding-horizontal">
        <div>
          <app-output label="Leads">
            <span>{{ contactData.lead | emptyValue }}</span>
            @if (contactData.lead && contactData.leadBackup) {
              <span>,</span>
            }
            <span>{{ contactData.leadBackup }}</span>
          </app-output>
        </div>
      </div>

      @if (contactData.companyName || contactData.parentCompanyName) {
        <mat-divider></mat-divider>
      }

      @for (phone of contactData.phone | splitBy: '|'; track phone) {
        <div class="abs-clickable" (click)="callTel(phone)">
          <div class="info-item-actions abs-padding">
            <mat-icon class="abs-padding-half" fontIcon="mdi-phone"></mat-icon>
            <span class="abs-margin-left">{{ phone }}</span>
          </div>
          <mat-divider></mat-divider>
        </div>
      }

      @for (email of contactData.email | splitBy: '|'; track email) {
        <div class="abs-clickable" (click)="sendEmail(email)">
          <div class="info-item-actions abs-padding">
            <mat-icon class="abs-padding-half" fontIcon="mdi-email"></mat-icon>
            <span class="abs-margin-left">{{ email }}</span>
          </div>
          <mat-divider></mat-divider>
        </div>
      }

      @if (contactData.contactNotes) {
        <div class="tw-flex tw-h-auto tw-max-w-[500px] tw-items-center tw-px-0 tw-py-1">
          <app-output label="Notes" class="tw-flex-1">
            {{ contactData.contactNotes }}
          </app-output>
        </div>
      }
    </div>
  </div>
</ng-template>
