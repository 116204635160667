<div class="tw-flex tw-flex-1 tw-flex-col">
  <div class="abs-padding tw-flex tw-flex-1 tw-items-center tw-gap-1">
    @if (selectable) {
      <div style="width: 28px"></div>
    }

    <span class="mat-header-cell abs-align-center tw-flex tw-max-w-[15%] tw-flex-1 tw-flex-col @xs:tw-max-w-[6%]">Date</span>
    <span fxFlex class="mat-header-cell">Account</span>
    <span fxFlex="24" class="mat-header-cell abs-container-gt-xs">Portfolio</span>
    <span fxFlex="13" class="mat-header-cell abs-container-gt-xs">Type</span>
    <span class="mat-header-cell abs-align-center tw-flex tw-max-w-[20%] tw-flex-1 tw-flex-col @xs:tw-max-w-[13%]">Value</span>
    <mat-icon fxFlex="none" class="mat-header-cell abs-margin-half-right"></mat-icon>
  </div>
  <mat-divider></mat-divider>
</div>
