<app-dialog-title displayTitle="Jobs" [loading]="saving() || loading()">
  <button mat-icon-button type="button" (click)="addJobFormAtStart()" [disabled]="saving()">
    <mat-icon fontIcon="mdi-plus-circle" color="primary"></mat-icon>
  </button>
</app-dialog-title>

<mat-dialog-content>
  <form (submit)="save()" id="job-form">
    <div class="tw-flex tw-flex-col">
      @for (jobForm of formArray.controls; track jobForm; let i = $index) {
        <app-contact-job-form
          [contactJob]="getJob(i)"
          [contactJobForm]="$any(jobForm)"
          [index]="i"
          (remove)="remove($event)"
          class="abs-dashed-box abs-margin-bottom"
          [ngClass]="{ 'invalid-form': jobForm.touched && jobForm.hasError('dateLessThan') }"
          [idtContact]="idtContact"
        ></app-contact-job-form>
        @if (jobForm.touched && jobForm.hasError('dateLessThan')) {
          <mat-error class="abs-margin-bottom"> End date must be greater than the start date </mat-error>
        }
      }
    </div>
  </form>
</mat-dialog-content>

<div mat-dialog-actions>
  <button mat-button matDialogClose>Cancel</button>
  <span class="tw-flex-1"></span>
  <button mat-raised-button color="primary" type="submit" [disabled]="saving()" form="job-form">Save</button>
</div>
