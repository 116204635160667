<mat-form-field class="tw-flex tw-flex-1">
  <mat-label>{{ label() }}</mat-label>

  <mat-chip-grid #chipList [formControl]="control()">
    @for (entity of control().value; track entity.idtUser; let first = $first) {
      <mat-chip-row (removed)="remove(entity.idtUser)" [value]="entity">
        <mat-icon matChipAvatar fontIcon="mdi-account" class="abs-margin-half-right"></mat-icon>
        {{ entity.name }}
        @if (textToFirst() && first) {
          <span>&nbsp;(author)</span>
        }
        <button matChipRemove>
          <mat-icon fontIcon="mdi-close"></mat-icon>
        </button>
      </mat-chip-row>
    }
    <input [placeholder]="placeholder()" #input [matAutocomplete]="auto" [matChipInputFor]="chipList" />
  </mat-chip-grid>
  <mat-autocomplete requireSelection #auto="matAutocomplete" (optionSelected)="selected($event)">
    @for (entity of filteredResults(); track entity.idtUser) {
      <mat-option [value]="entity">
        <span [class.abs-color-faded]="entity.inactive">{{ entity.name }}</span>
      </mat-option>
    }
  </mat-autocomplete>
</mat-form-field>
